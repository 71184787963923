// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-doctor-guide-js": () => import("./../../../src/pages/doctor-guide.js" /* webpackChunkName: "component---src-pages-doctor-guide-js" */),
  "component---src-pages-hodgkin-lymphoma-treatment-discussion-guide-js": () => import("./../../../src/pages/hodgkin-lymphoma-treatment-discussion-guide.js" /* webpackChunkName: "component---src-pages-hodgkin-lymphoma-treatment-discussion-guide-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-videos-js": () => import("./../../../src/pages/videos.js" /* webpackChunkName: "component---src-pages-videos-js" */),
  "component---src-templates-video-asset-template-js": () => import("./../../../src/templates/video-asset-template.js" /* webpackChunkName: "component---src-templates-video-asset-template-js" */)
}

